<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Entry -->
        <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            variant="primary"
            :to="{ name: 'apps-master-data-cashbank-add'}"
          >
            <feather-icon icon="PlusIcon" /> {{ $t('globalActions.create') }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-included-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>


      <!-- Column: Cash Bank -->
      <template #cell(code)="data">
        <b-link
          :to="{ name: 'apps-master-data-cashbank-detail', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          <span class="font-weight-bolder d-block text-nowrap">{{ data.item.code }} - {{ data.item.name }}</span>
        </b-link>
        
      </template>

      <!-- Column: Type -->
      <template #cell(is_bank)="{ item }">
        <b-badge pill :variant="item.is_bank ? 'light-success' : 'light-warning'">{{ item.is_bank ? 'Bank' : 'Cash' }}</b-badge>
      </template>

      <!-- Column: Bank Info -->
      <template #cell(bank_account_number)="{ item }">
        {{ item.is_bank ?  item.bank.name + ' - ' + item.bank_account_number : ''}}
      </template>

          
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refInvoiceListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge
} from 'bootstrap-vue'

// import useList from './useList'
import useListTable from '@/comp-functions/useListTable'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BBadge
  },
  setup () {
    // Table Handlers
    const tableColumns = [
      { key: 'code', label: 'Cash Bank', sortable: true },
      { key: 'office.label', label: 'Office' },
      { key: 'is_bank', label: 'Type'},
      { key: 'bank_account_number', label: 'Bank Info'},
      { key: 'account.label', label: 'C.O.A'}
    ]

    // Table Handlers
    const columnToBeFilter = [
      'code',
      'name',
      'is_bank',
      'description'
    ]

    return {
      tableColumns,
      columnToBeFilter,
      ...useListTable({
        url: 'master/cashbank'
      })
    }
  }
}
</script>
